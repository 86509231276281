var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        persistent: "",
        scrollable: "",
        color: "background",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialogModalAgrupador,
        callback: function($$v) {
          _vm.dialogModalAgrupador = $$v
        },
        expression: "dialogModalAgrupador"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", dark: "", color: "primary" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        dark: "",
                        disabled: _vm.loadingSalvar
                      },
                      on: {
                        click: function($event) {
                          return _vm.fecharModal()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.tituloModal))])
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("div", { staticClass: "pa-3" }, [
            _c(
              "div",
              { staticClass: "d-flex align-center justify-space-between pr-4" },
              [
                _c(
                  "div",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mx-3", attrs: { large: "" } },
                      [_vm._v(" mdi-chevron-down ")]
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.tipoEdicao === "CRIAR"
                            ? "Informe o Nome de identificação do Agrupador"
                            : "Atualize o Nome de identificação do Agrupador"
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function({ on, attrs }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "ml-2",
                                attrs: { fab: "", small: "", elevation: "0" }
                              },
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { color: "grey" } },
                                      "v-icon",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(" mdi-information-outline ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.tipoEdicao === "CRIAR"
                              ? "O Agrupador será utilizado para setorizar as consultas de Títulos que possuírem este marcador. Ao submeter um nome de identificação, nosso sistema irá gerar um códigode retorno"
                              : "Só é permitido atualizar o Nome de Identificação do agrupador, as demais informações são fixas e não podem ser alteradas."
                          )
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              [
                _vm.tipoEdicao === "EDITAR"
                  ? _c(
                      "v-row",
                      { staticClass: "pa-0 ma-0" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "pr-1",
                            attrs: {
                              cols: "12",
                              xs: "12",
                              sm: "12",
                              md: "6",
                              lg: "6"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "py-0 px-1",
                              attrs: {
                                disabled: "",
                                outlined: "",
                                "prepend-icon": !_vm.isExtraSmall
                                  ? "mdi-account-circle"
                                  : "",
                                name: "ID Agrupador",
                                label: "ID Agrupador",
                                type: "text",
                                "data-cy": "agrupadorId",
                                "hide-details": "auto"
                              },
                              model: {
                                value: _vm.agrupador.id_agrupador,
                                callback: function($$v) {
                                  _vm.$set(_vm.agrupador, "id_agrupador", $$v)
                                },
                                expression: "agrupador.id_agrupador"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "pl-1",
                            attrs: {
                              cols: "12",
                              xs: "12",
                              sm: "12",
                              md: "6",
                              lg: "6"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "py-0 px-1",
                              attrs: {
                                disabled: "",
                                outlined: "",
                                "prepend-icon": !_vm.isExtraSmall
                                  ? "mdi-account-circle"
                                  : "",
                                name: "Código Agrupador",
                                label: "Código Agrupador",
                                type: "text",
                                "data-cy": "agrupadorCodigo",
                                "hide-details": "auto"
                              },
                              model: {
                                value: _vm.agrupador.ds_agrupador,
                                callback: function($$v) {
                                  _vm.$set(_vm.agrupador, "ds_agrupador", $$v)
                                },
                                expression: "agrupador.ds_agrupador"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-form",
                  {
                    ref: "form",
                    model: {
                      value: _vm.formularioValido,
                      callback: function($$v) {
                        _vm.formularioValido = $$v
                      },
                      expression: "formularioValido"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "pt-4 px-4 pb-2",
                      attrs: {
                        outlined: "",
                        "prepend-icon": !_vm.isExtraSmall
                          ? "mdi-account-circle"
                          : "",
                        name: "Nome Agrupador",
                        label: "Nome Agrupador",
                        type: "text",
                        counter: "",
                        maxlength: "100",
                        hint: "Tamanho máximo 100 caracteres",
                        required: "",
                        rules: _vm.regrasNomeAgrupador,
                        "data-cy": "agrupadorNome"
                      },
                      model: {
                        value: _vm.agrupador.nm_agrupador,
                        callback: function($$v) {
                          _vm.$set(_vm.agrupador, "nm_agrupador", $$v)
                        },
                        expression: "agrupador.nm_agrupador"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            {
              style: !_vm.isExtraSmall
                ? "display: flex; justify-content: end"
                : "display: flex; justify-content: center"
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: {
                    disabled: !_vm.formularioValido,
                    width: _vm.isExtraSmall ? "100" : "120",
                    color: "green white--text",
                    loading: _vm.loadingSalvar,
                    "data-cy": "salvarAgrupador"
                  },
                  on: { click: _vm.salvarAgrupador }
                },
                [_vm._v(" Salvar ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ml-5",
                  attrs: {
                    dense: "",
                    color: "error",
                    width: _vm.isExtraSmall ? "100" : "120",
                    loading: _vm.loadingSalvar
                  },
                  on: {
                    click: function($event) {
                      return _vm.fecharModal()
                    }
                  }
                },
                [_vm._v(" Cancelar ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.mostrarSnackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.mostrarSnackbar,
              corSnackbar: _vm.corSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.mostrarSnackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }